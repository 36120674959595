import { render, staticRenderFns } from "./ViewAllAnnouncementOpen.html?vue&type=template&id=2350fe6e&scoped=true&"
import script from "./ViewAllAnnouncementOpen.vue?vue&type=script&lang=js&"
export * from "./ViewAllAnnouncementOpen.vue?vue&type=script&lang=js&"
import style0 from "./ViewAllAnnouncementOpen.scss?vue&type=style&index=0&id=2350fe6e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2350fe6e",
  null
  
)

export default component.exports