<template src="./ViewAllAnnouncementOpen.html"></template>

<script>
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import { mapGetters } from "vuex";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import announcementService from "../../services/Announcement";
import { roleName } from "@/utils/constants";

export default {
  name: "ViewAllAnnouncementOpen",
  components: {
    ArrobaMedellinArrobitaNoData,
    ArrobaMedellinSeeker,
    ChooseUniversity,
  },
  props: {
    title: {
      type: String,
    },
    TitleNotFound: {
      type: String,
      default: "Convocatorias",
    },
    notFound: {
      type: String,
      default: "No tienes convocatorias abiertas en este momento",
    },
    dataCalls: {
      type: Array,
    },
  },
  data() {
    return {
      buttonText: "Ver información",
      errorMessage: false,
      role: "",
      openCalls: [],
      infoSearch: [{}],
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.getAllInfo();
  },
  methods: {
    async searchStateOpen(word) {
      this.errorMessage = false;
      this.openCalls = [];
      try {
        if (!word) {
          this.getAllInfo();
        } else {
          this.infoSearch = await announcementService.getSearchAnnouncement({
            word: word,
            take: 5,
            skip: 0,
            publicState: "both",
            institutionId: this.institutionId,
          });
          this.openCalls = this.infoSearch.open;
          if (!this.openCalls.length) this.errorMessage = true;
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    async getAllInfo() {
      this.openCalls = [];
      try {
        if (this.role === roleName.ADMINISTRATOR_CALL) {
          const objOpenCalls = {
            take: 50,
            skip: 0,
            state: true,
          };
          this.openCalls = await announcementService.listAnnouncement(
            objOpenCalls
          );
        } else {
          const objOpenCalls = {
            state: true,
            institution: this.institutionId,
            publicState: "both",
          };
          this.openCalls =
            await announcementService.announcementByStateAndInstitution(
              objOpenCalls
            );
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidation() {
      return ["Student", "Teacher", "Entrepreneur"].some(
        (a) => a === this.role
      );
    },
    roleValidationAdminAnnouncement() {
      return ["AdministratorCall"].some((a) => a === this.role);
    },
    roleValidationTeacherInvestigator() {
      return ["TeacherInvestigator"].some((a) => a === this.role);
    },
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    targetAudiences() {
      return (publicState) => {
        switch (publicState) {
          case "external":
            return "externo";
          case "internal":
            return "interno";
          case "both":
            return "ambos";
          default:
            return "";
        }
      };
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.getAllInfo();
    },
  },
};
</script>

<style scoped lang="scss" src="./ViewAllAnnouncementOpen.scss"></style>